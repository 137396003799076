
import {ColoredTreePicker} from './coloredtreepicker.js';
import {TreePicker, append_html_to} from './treepicker.js';
export {ColoredTreePicker, TreePicker, append_html_to};

customElements.define('color-tree-picker', ColoredTreePicker);
customElements.define('tree-picker', TreePicker);

/*

<tree-picker use-name-as-label=true>
</tree-picker>

*/


